<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px">
            <div
              title="学员姓名"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel" style="min-width:8rem">学员姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="学员姓名"
                clearable
              />
            </div>
            <div
              title="学员身份证号"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel" style="min-width:7rem"
                >学员身份证号:</span
              >
              <el-input
                v-model="idcard"
                type="text"
                size="small"
                placeholder="学员身份证号"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
                  @change="(val)=>{cleanarea(val,'areaId')}"
              ></el-cascader>
            </div>
            <div title="性别" class="searchboxItem ci-full">
              <span class="itemLabel">性别:</span>
             <el-select
                clearable
                size="small"
                v-model="sex"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">机构名称:</span>
              <el-input
               @change="cleancompanyName"
                v-model="companyName"
                type="text"
                size="small"
                placeholder="机构名称"
                clearable
              />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select
               @change="clean"
                clearable
                size="small"
                v-model="studyState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in studyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="学员类型" class="searchboxItem ci-full">
              <span class="itemLabel">学员类型:</span>
 <el-select v-model="userType" placeholder="请选择" size="small" clearable>
                  <el-option
                  
                      v-for="item in userTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="学习开始时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">学习开始时间:</span>
              <el-date-picker
                v-model="trainingTime"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>

            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="180"
              />
              <el-table-column
                label="性别"
                align="left"
                prop="sex"
                show-overflow-tooltip
                min-width="50"
              >
              <template slot-scope="scope"> {{scope.row.sex == '1' ? '男': '女'}}</template>
              </el-table-column>
              <el-table-column
                label="学员类型"
                align="center"
                prop="userType"
                show-overflow-tooltip
                min-width="100"
              >
               <template slot-scope="scope">  {{ $setDictionary("USERTYPE_SX", scope.row.userType) }}</template>
              </el-table-column>

              <el-table-column
                label="工种"
                align="center"
                show-overflow-tooltip
                prop="occupationName"
                min-width="200"
              />
              <el-table-column
                label="等级"
                align="left"
                prop="trainLevelName"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="学习进度(%)"
                align="left"
                prop="studyPercentage"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="状态"
                align="left"
                prop="studyState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("STUDY_STATUS", scope.row.studyState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="left"
                prop="companyName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="学习开始时间"
                align="left"
                prop="startTime"
                show-overflow-tooltip
                minWidth="160"
              >
              <template slot-scope="scope">
                {{scope.row.startTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="360px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="lookArchives(scope.row.userId,scope.row.projectId,'pdf')"
                    >查看档案</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="learningRecord(scope.row.userId,scope.row.userName,scope.row.idcard,scope.row.projectId)"
                    >学习记录</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="lookCertificate(scope.row.userId,scope.row.projectId,'pdf')"
                    >查看合格证</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
     <el-dialog class="dialog2"
        :title="dialogTitle"
        :visible.sync="dialog1"
        width="50%"
        top="15px"
        center
        >
         <div id="pdf-content" style="height: 800px" v-if="stu == 'pdf'"></div>
      </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      userName: "", //学员姓名
      idcard: "", //身份证号
      trainingTime: "", //培训时间
      areatreeList: [], // 行政区划
      studyState: "", //学习状态
      companyName:'', //机构名称
      sex:'', //性别
      areaList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      areaId: "", //行政区划
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      studyStatusList: [],
      params1: {},
      disable: true,
       search: {
         areaId:true,
        studyState: true,
        companyName:true
      },
      sexList:[
        {
          value:'1',
          label:'男'
        },
        {
          value:'2',
          label:'女'
        }
      ],
      userTypeList:[],
      userType:'',
      dialog1:false,
      ImgSrc:'',
      dialogTitle:'',
    };
  },
  created() {
    this.getareatree();
    this.getstydyStatusList();
    this.getUserTypeList();
    
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
      getUserTypeList() {
      const userTypeList = this.$setDictionary("USERTYPE_SX", "list") || [];
      let list = [];
      for (const key in userTypeList) {
        list.push({
          value: key,
          label: userTypeList[key],
        });
      }
      this.userTypeList = list;
    },
    getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
        list.push({
          value: key,
          label: studyStatusList[key],
        });
      }
      this.studyStatusList = list;
    },
    //导出
    handleExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};
          if (this.userName) {
            params.userName = this.userName;
          }
          if (this.idcard) {
            params.idcard = this.idcard;
          }
          if (this.areaId) {
            params.areaId = this.areaId;
          }
           if (this.sex) {
        params.sex = this.sex;
      }
           if (this.userType) {
        params.userType = this.userType;
      }
          if (this.trainingTime) {
            params.trainingTimeStart = this.trainingTime[0];
            params.trainingTimeEnd = this.trainingTime[1];
          }
          if (this.studyState) {
            params.studyState = this.studyState || this.$route.query.params;
          }
          if (this.companyName) {
        params.companyName = this.companyName;
      }
          if (this.params1.trainFormId) {
            params.trainTypeId = this.params1.trainFormId;
          }
          if (this.params1.levelFormId) {
            params.trainLevelId = this.params1.levelFormId;
          }
          if (this.params1.occFormId) {
            params.occupationId = this.params1.occFormId;
          }
          if (this.params1.postFormId) {
            params.postId = this.params1.postFormId;
          }
          if (this.params1.industryFormId) {
            params.industryId = this.params1.industryFormId;
          }
          this.$post(
            "/agency-regulation/statistical-user/exportUser",
            params
          ).then((res) => {
            if (res.status == "0") {
              window.open(res.message);
            }
          });
        })
        .catch(() => {});
    },
    getData(pageNum = 1) {
      if (this.search.studyState) {
        this.studyState = this.$route.query.studyState;
      }
        if (this.search.areaId) {
        this.areaId = this.$route.query.areaId;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.sex) {
        params.sex = this.sex;
      }
      if (this.userType) {
        params.userType = this.userType;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainingTime) {
        params.trainingTimeStart = this.trainingTime[0];
        params.trainingTimeEnd = this.trainingTime[1];
      }
    
      if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.search.companyName) {
        this.companyName = this.$route.query.companyName;
      }
      if (this.companyName) {
        params.companyName = this.companyName;
      }
      if (this.params1.trainFormId) {
        params.trainTypeId = this.params1.trainFormId;
      }
      if (this.params1.levelFormId) {
        params.trainLevelId = this.params1.levelFormId;
      }
      if (this.params1.occFormId) {
        params.occupationId = this.params1.occFormId;
      }
      if (this.params1.postFormId) {
        params.postId = this.params1.postFormId;
      }
      if (this.params1.industryFormId) {
        params.industryId = this.params1.industryFormId;
      }
      this.doFetch({
        url: "/agency-regulation/statistical-user/pageList",
        params,
        pageNum,
      });
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/gov/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    childBack(params) {
      this.params1 = { ...params };
    },
    clearParams() {
      this.params1 = {};
    },
    //学习记录
    learningRecord(userId,userName,
idcard,projectId) {
      //  sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path:'/web/shanxiSupervisionEnd/StudentLearnRecord',
        query:{userId:userId,userName:userName,idcard:idcard,projectId:projectId}
      })
    },
    //查看档案
    lookArchives(userId,projectId,stu) {
       this.stu = stu;
       this.dialogTitle = '查看档案'
      this.$post('/cert/downloadTrainDocument',{userId,projectId}).then(ret => {
        if (ret.status== 0) {
          this.dialog1 = true;
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-content");
          });
        } else {
          this.$message({
            message: '暂无档案查看',
            type: 'error'
          })
        }
      
      })
    },
     //查看合格证
    lookCertificate(userId,projectId,stu) {
      this.stu = stu;
      this.dialogTitle = '查看合格证'
      this.$post('/cert/downloadCert',{userId,projectId}).then(ret => {
        if(ret.status == 0) {
          this.dialog1 = true;
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-content");
          });
        } else {
          this.$message({
            message:'暂无合格证',
            type:'error'
          })
        }
        

      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    cleanarea(val) {
      console.log(val);
      this.areaId = val;
      this.search.areaId = false;
    },
    clean(val) {
      this.studyState = val;
      this.search.studyState = false;
    },
    cleancompanyName(val) {
      this.companyName = val;
      this.search.companyName = false;
    },
    beforeRouteLeave: resetKeepAlive,
     watch: {
    $route: function(val) {
      if (JSON.stringify(val.query) != "{}") {
        this.search.areaId = true;
        this.search.studyState = true;
        this.areaId = "";
        // this.studyState = "";
        this.getData(-1);
      }
    }
  }
  },
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
